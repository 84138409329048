body {
  margin: 0; /* Remove default margin */
  overflow: hidden;
}

#root {
  height: 100vh;
  background-color: rgba(245, 245, 220, 0.5); /* Transparent background color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Add relative positioning to #root */
  width: 100%; /* Make the title span the full width of #root */

}

.Title {
  /* Styles for the title */
  color: black; /* Set the text color to black */
  font-size: 35px; /* Adjust the font size as desired */
  font-weight: bold; /* Make the text bold */
  position: fixed; /* Make the title position absolute */
  top: 0;
  left: 0;
  width: 100%; /* Make the title span the full width of #root */
  background-color: rgba(245, 245, 220); /* Transparent background color */
  padding: 10px 0; /* Add some padding for spacing */
  text-align: center; /* Center-align the text */
  z-index: 1500; /* Set a high z-index to ensure it's on top */
  margin: 0;
}

.Title2 {
  color: white; /* Set the text color to white */
  font-size: 35px; /* Adjust the font size as desired */
  font-weight: bold; /* Make labels bold */
}


.center-form {
  display: flex;
  align-items: center; /* CHANGED */
  text-align: center;
  flex-direction: column;
  border: 1px solid white; /* Border style, color, and thickness */
  padding: 20px; /* Padding inside the container */
  border-radius: 10px; /* Rounded corners */
  max-width: 400px; /* Maximum width for the form container */
  margin: 0 auto; /* Center horizontally */
}

.centered-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.centered-section label {
  display: block; /* Ensures the label takes up the full width */
  text-align: center; /* Aligns the label text to the left */
  margin-bottom: 5px; /* Adds space between the label and the input below it */
  width: 100%; /* Ensures label width matches its container, helpful if container width changes */
}

.centered-section input,
.centered-section textarea {
  width: 100%; /* Ensures inputs take full width of their container */
  padding: 10px; /* Adds padding for better text visibility and field interaction */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.centered-section textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px; /* Adjust this value to increase/decrease space */
}


/* explainer.css */
.explainer-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px; /* Increased padding */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Drop shadow */
  font-size: 16px; /* Font size */
  line-height: 1.5; /* Line height */
  color: #333; /* Text color */
  max-width: 400px; /* Maximum width */
  margin: 0 auto; /* Center horizontally */
  text-align: left; /* Text alignment */
}




.explainer-close-button {
  background-color: transparent; /* Set background color to transparent */
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px; /* Increase the padding for a larger button */
  color: black; /* Set the text color to green */
  border: none;
  font-size: 5em; /* Increase the font size for a larger button */
  cursor: pointer;
}
/* Media queries for devices with a max-width of 768px */
@media (max-width: 768px) {
  .explainer-container {
    font-size: 12px; /* Reduced font size for medium screens */
  }
}

/* Further adjustments for very small screens, typically smartphones */
@media (max-width: 480px) {
  .explainer-container {
    font-size: 10px; /* Further reduced font size for small screens */
    padding: 10px; /* Reduced padding */
  }
}

.explainer-containerPatchNotes {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px; /* Increased padding */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Drop shadow */
  font-size: 12px; /* Font size */
  line-height: 1.5; /* Line height */
  color: #333; /* Text color */
  max-width: 400px; /* Maximum width */
  margin: 0 auto; /* Center horizontally */
  text-align: left; /* Text alignment */
  float: center;
}

@media (max-width: 768px) {
  .explainer-containerPatchNotes {
    font-size: 12px; /* Reduced font size for medium screens */
  }
}

/* Further adjustments for very small screens, typically smartphones */
@media (max-width: 480px) {
  .explainer-containerPatchNotes {
    font-size: 10px; /* Further reduced font size for small screens */
    padding: 10px; /* Reduced padding */
  }
}




.footer {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  font-size: 10px; /* Set the font size */
}

.cluster-icon {
  background-color: #4d4e0b;
  height: 2em;
  width: 2em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

/* Burrito Rating */

.centeredRatinglabel { 
  display: flex;
  align-items: center; /* Ensures the text and icons are vertically centered */
  color: black;
  border: none;
  padding: 10px 20px;
  outline: none;
  background: white;
  border-radius: 30px; /* Oval shape */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  width: 50%; /* Sets the width to half of its container */
  margin-left: 0; /* Aligns the label to the left side of its container */
}

@media (max-width: 768px) {
  .centeredRatinglabel {

    width: 80%;
  }
}

.burritoSeparatorForm {
    display: inline-block;
    width: 30px; /* Increased size for visibility */
    height: 40px; /* Increased size for visibility */
    background-image: url('./assets/burrito2.ico');
    background-size: 90%; /* This will make the background image cover the entire container */
    background-repeat: no-repeat;
    margin: 0 5px; /* Adjusted spacing between icons */
    cursor: pointer;
    vertical-align: middle;
}

.reviewRatingLabel {
  display: block; /* or flex, depending on your layout needs */
  margin-bottom: 20px; /* Adds space below the label */
  color: black;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

textarea[name="review"] {
  width: 100%; /* Full width of its container */
  height: 100px; /* Fixed height, adjust as needed */
  padding: 10px; /* Comfortable padding inside the textarea */
  border: none; /* No border */
  outline: none; /* No outline on focus */
  border-radius: 15px; /* Oval shape */
  background-color: white; /* White background */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  resize: none; /* Disables resizing, optional */
}

.reviewRatingContainer {
  display: flex;
  align-items: center; /* Aligns children vertically in the middle */
  background-color: white; /* White background */
  padding: 10px; /* Some padding around the content */
}


.ratingContainer {
  display: flex;
  align-items: center; /* Centers the rating horizontally */
  justify-content: flex-end; /* Aligns the rating to the right */
}

.burritoSeparator {
  display: inline-block;
  width: 20px; /* Adjusted size */
  height: 20px; /* Adjusted size */
  background-image: url('./assets/burrito2.ico');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 2px; /* Smaller space for closer burritos */
  cursor: pointer; /* Indicates clickable */
  vertical-align: middle; /* Aligns with the text */
}

.burritoSeparator.active {
  filter: none; /* Full color for selected burritos */
}



@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/* Style the Zoom Control button and set its position to fixed at the top-right corner */
.leaflet-control-container { 
  position: fixed;   
  top: 110px; /* Adjust the top position as needed */
  left: 0px; /* Adjust the left position as needed */
  z-index: 1000; /* Higher value to bring to front */
} 


/* Style the Position Control button and set its position to fixed at the bottom-right corner */
#crosshairs{
  position: fixed;
  top: 200px; /* Adjust the top position as needed */
  left: 12px; /* Adjust the left position as needed */
}

/* Style the Question button and set its position to fixed at the top-left corner */
.leaflet-top.leaflet-left #toggleExplainer {
  position: fixed;
  top: 250px; /* Adjust the top position as needed */
  left: 12px; /* Adjust the left position as needed */
  background-color: #FFDE03;
}


/* Simplified control */
.filter-control {
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Adjusted to center */
  background: white; /* Set background of the entire control to white */
  padding: 10px; /* Add some padding around the control */
  border-radius: 5px; /* Optional: rounds the corners of the control background */
}

/* Default styles for larger screens (desktops) */
.filter-control {
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Adjusted to center */
  background: white; /* Set background of the entire control to white */
  padding: 10px; /* Add some padding around the control */
  border-radius: 5px; /* Optional: rounds the corners of the control background */
}

/* Styles for mobile devices */
@media (max-width: 600px) {
  .filter-control {
    transform: scale(0.75); /* Reduces the size to 75% */
    top: 50px; /* Optionally adjust the top position for mobile */
    right: 5px; /* Optionally adjust the right position for mobile */
  }
}




/* Ensure the leaflet container is positioned relatively */
.leaflet-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

/* Styling for buttons */
/* Styling for buttons */
.selector {
  background-color: #d6d6d6; /* Grey color for not selected */
  color: #000; /* Black text color for better contrast */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.8rem;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selector:hover {
  background-color: #bfbfbf; /* Slightly darker shade of grey for hover state */
}

.selector.selected {
  background-color: #FFDE03; /* Bootstrap primary blue for selected */
  color: black; /* White text color for selected */
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column; /* This ensures vertical layout */
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0px; /* Adds padding inside the container, adjust as needed */
}

.modal-content, .modal-content2 {
  background: linear-gradient(135deg, rgb(253, 253, 253) 20%, rgb(180, 21, 18) 100%); /* Light yellow to white gradient */
  color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%; /* Adjusted width to 90% for better utilization of space */
  max-width: 600px; /* Maximum width limited to 600px */
  margin-bottom: 20px; /* Creates space between the two sections */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 900px) {
  .modal-content, .modal-content2 {
    width: 80%; /* Increases width on small screens */
    padding: 20px;
    max-width: 95%; /* Allows modal to expand to most of the screen width */
    margin: 100px auto -50px;

  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .modal-content, .modal-content2 {
    width: 100%; /* Full width on very small screens */
    max-width: 100%; /* Ensure modal takes up all available width */
    padding: 15px; /* Slightly reduced padding */
    font-size: 14px; /* Slightly smaller font size for content */
    margin: 100px auto -50px;
  }
}



.Formmodal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Ensure that the backdrop can scroll if content is larger than the screen */

}

.Formmodal-content {
  background: linear-gradient(135deg, rgb(255, 222, 3) 20%, rgb(253, 253, 253) 100%);
  color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  margin-bottom: 16px; /* Space between form and reviews */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: auto;
}

.Formmodal-content2 {
  background: linear-gradient(135deg, rgb(253, 253, 253) 10%, rgb(255, 222, 3) 100%);
  color: #333;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 16px; /* Space between form and reviews */
  margin-bottom: auto; /* Space between form and reviews */
  overflow-y: auto; /* Allows scrolling within the reviews section if content overflows */
}
.OnlineRatingsContent {
  display: flex;            /* Enables flexbox layout */
  flex-direction: column;   /* Stack children vertically */
  justify-content: center;  /* Center vertically */
  align-items: center;  
}
/* Media queries for devices with a max-width of 375px (common for smaller iPhones) */
@media (max-width: 768px) {
  .Formmodal-content, .Formmodal-content2 {
    font-size: 12px; /* Smaller font size for smaller screens */
    padding: 10px;
    width: 90%;
    transform: scale(0.9); /* Reduces the size to 75% */
    margin-top: -16px; /* Space between form and reviews */
  }
}

/* Form buttons */


.Reccomendationmodal-backdrop {
  background: linear-gradient(135deg, rgb(253, 253, 253) 10% , rgb(255, 222, 3) 100%); /* Light yellow to white gradient */
  color: #333; /* Dark grey text color for better readability */
  padding: 20px;
  border-radius: 8px;
  width: 100%; /* Ensure they take the full width available within the modal-backdrop */
  max-width: 100%;
  margin: 0 auto; /* Centers the modal content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More prominent shadow for a 3D effect */
}


.modal-content {
  background: linear-gradient(135deg, rgb(255, 222, 3) 10%, rgb(253, 253, 253) 100%); /* Adjusted for transparency */
  color: #333; /* Dark grey text color for better readability */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More prominent shadow for a 3D effect */
  text-align: center;
  margin: 100px auto -50px;
  font-family: 'Arial', sans-serif; /* Change the font family if desired */
  font-size: 16px; /* Adjust the font size for readability */
  margin-top: 30px;
  width: 80%; /* Increases width on small screens */


}


.close-button {
  font-size: 2em; /* Larger close button for mobile */
  padding: 15px; /* Larger touch area */
}




/* If you have titles or headers in your modal, style them separately for emphasis */
.modal-title {
  font-size: 24px; /* Larger font size for titles */
  margin-bottom: 10px; /* Add some space below the title */
  color: #1a1a1a; /* Slightly darker color for the title */
  font-weight: bold; /* Make the title bold */
}


/* Style for buttons within the modal, if any */
.modal-button {
  background-color: #4CAF50; /* Green color for buttons */
  color: white; /* White text on buttons */
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px; /* Slightly smaller font size for buttons */
  margin-top: 15px; /* Space above the button */
}

.modal-button:hover {
  background-color: #43a047; /* Darker shade of green on hover */
}

button {
  background-color: white; /* Example: green background */
  color: black; /* White text */
}

/* burrito for bullet point */
.reviewsList {
  list-style-type: none;  /* Removes default list-style */
  padding: 0;             /* Reset padding */
}

.reviewItem {
  background-image: none;
  padding-left: 0;
  line-height: 20px;             /* Adjust based on your design */
  font-size: 16px;               /* Ensure text is balanced */
  margin-bottom: 10px;           /* Space between items */
}

.burritoSeparator {
  display: inline-block;
  width: 16px;                   /* Same as your burrito image size */
  height: 16px;                  /* Same as your burrito image size */
  background-image: url('./assets/burrito2.ico');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 5px;                 /* Space around the image */
  vertical-align: middle;        /* Aligns with the text */
}

.burritoSeparatorForm input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}



/*patch notes*/

.explainer-containerPatchNotes {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  position: absolute; /* Positioning absolute */
  top: 10%; /* Adjust as needed */
  left: 10%; /* Adjust as needed */
  right: 10%; /* Adjust as needed */
  bottom: 10%; /* Adjust as needed */
  background-color: white; /* Optional: to give a background color */
  padding: 20px; /* Optional: to give some padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: to add some shadow */
}

.patch-notes {
  flex-grow: 1; /* Take remaining space within the flex container */
  font-size: 0.75rem; /* Smaller font size */
  color: #666; /* Lighter text color if needed */
  margin-top: 20px; /* Space from the previous content */
  overflow-y: auto; /* Allows scrolling within the reviews section if content overflows */
}

.patch-notes h2 {
  font-size: 1rem; /* Smaller heading for the section */
}

.patch-notes h3 {
  font-size: 0.85rem; /* Smaller heading for month names */
}

.patch-notes ul {
  padding-left: 20px; /* Indent for list */
  margin-top: 5px; /* Smaller margin between months */
}


/* flash online rating */
.flash {
  width: 80%;
  animation: flash-animation 2s ease-in-out;
}


@keyframes flash-animation {
  0% {
    background-color: yellow;
    color: white;
  }
  50% {
    background-color: white;
    color: black;
  }
  100% {
    background-color: yellow;
    color: white;
  }
}

/*marker loading icon */
.loading-icon {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
